import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import api from "../../context/api";

const CopywritingPhone = () => {
    const [copywritingsPhones, setCopywritings] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCopywriting, setEditingCopywriting] = useState(null);

    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCopywritings();
    }, []);

    const fetchCopywritings = async () => {
        try {
            const response = await api.get('/copywritingsPhone');
            setCopywritings(response.data);
        } catch (error) {
            console.error("Erro ao buscar os CopywritingPhone:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCopywriting(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
    };

    const handleEditCopywriting = (copywritingPhone) => {
        setIsEditing(true);
        setEditingCopywriting(copywritingPhone);
        setTitle(copywritingPhone.title);
        setSub(copywritingPhone.sub);
        setText(copywritingPhone.text);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCopywriting = async (copywritingId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/copywritingsPhone/${copywritingId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCopywritings(); // Atualizar a lista de copywritingsPhones

            toast.success('Offer deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o copywritingPhone:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCopywritingId, setDeleteModalCopywritingId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const postData = {
            title,
            sub,
            text
        };

        try {
            if (isEditing && editingCopywriting) {
                await axios.put(`${process.env.REACT_APP_API_URL}/copywritingsPhone/${editingCopywriting._id}`, postData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/copywritingsPhone`, postData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCopywritings();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o postagem:", error);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <Container>
            <div className="row">
                <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button
                            onClick={handleOpenModal}
                            variant="outline-primary"
                            size="sm"
                        >
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                    <h3><i className="fa fa-fw fa-phone-square" />Introdução da página</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Subtítulo</th>
                                <th>Texto</th>
                                <th className="acoes">Ações</th>
                            </tr>
                        </thead>
                        <tbody>

                            {copywritingsPhones.map((copywritingPhone) => (
                                <tr key={copywritingPhone._id}>

                                    <td>{copywritingPhone.title.slice(0, 15) + "..."}</td>

                                    <td>{copywritingPhone.sub.slice(0, 50) + "..."}</td>
                                    <td>
                                        <TextRender htmlContent={copywritingPhone.text.slice(0, 50) + "..."} />
                                    </td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditCopywriting(copywritingPhone)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteModalCopywritingId(copywritingPhone._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                            </div>

                                            {/* <Button variant="primary" onClick={() => handleEditCopywriting(copywritingPhone)}>Editar</Button>
                                            <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteModalCopywritingId(copywritingPhone._id); }}>
                                                Excluir
                                            </Button> */}

                                            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Tem certeza que deseja excluir este copywritingPhone? {deleteModalCopywritingId}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button variant="danger" onClick={() => { handleDeleteCopywriting(deleteModalCopywritingId); setShowDeleteModal(false); }}>
                                                        Excluir
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Offer" : "Cadastrar CopywritingPhone"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>

                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </Container>
    );
};

export default CopywritingPhone;