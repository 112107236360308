import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import Logo from "../../assets/images/cms-icon.png";
import LogoGiga from "../../assets/images/logoGiga.png";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import jwtDecode from "jwt-decode";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";

import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "font-awesome/css/font-awesome.min.css";
import styled from "styled-components";
import './styles.css';

// Importações das páginas
import Dashboard from '../Dashboard/Dashboard';
import Internet from '../Internet';
import CardSale from '../../components/HomeComponents/CardSale';
import Tv from '../Tv';
import Phone from '../Phone';
import About from '../About';
import Videos from '../Videos';
import CardOffer from '../../components/HomeComponents/CardOffer';
import RuralInternet from '../RuralInternet';
import Cidades from '../Cidades/Cidades';
import Combos from '../Combos/Combos';
import ListaCurriculos from '../CurriculoView/ListaCurriculos';
import CurriculoView from '../CurriculoView/CurriculoView';
import Products from '../Products/Products';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import MessagesTable from '../../components/MessagesTable/MessagesTable';
import Home from '../Home';
import ChatbotUsersTable from '../../components/ChatbotUserList/ChatbotUsersTable';


const Main = styled.main`
  position: relative;
  overflow: auto;
  transition: all 0.15s;
  background-color: #ffffff;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
  height: calc(100vh - 56px - 57px);
  overflow-x: hidden;
`;

const Head = styled.div`
  position: relative;
  overflow: auto;
  height: 56px;
`;

const Bar = styled.div`
  position: relative;
  height: calc(100vh - 56px );
`;

const Separator = styled.div`
  clear: both;
  position: relative;
  margin: 0.8rem;
  background-color: #f9dcdd;
  height: 1px;
`;

const SideNavF = styled(SideNav)`
  background: #222;
`;

const Menu = () => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [user, setUser] = useState(null);
  const [view, setView] = useState("dashboard");
  const [expanded, setState] = useState(false);
  const [loading, setLoading] = useState(true);
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleViewChange = (newView) => {
    setView(newView); // Altera o estado para a nova visualização
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserName(decodedToken.name || "Usuário");
        setUserEmail(decodedToken.email || "email@exemplo.com");
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        setAuth(null);
        navigate("/login");
      }
    } else {
      setAuth(null);
      navigate("/login");
    }
  }, [setAuth, navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setUser(response.data[0]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setAuth(null);
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [auth, setAuth, navigate]);

  // Exibir um indicador de carregamento enquanto os dados estão sendo carregados
  if (loading) {
    return <div className='spinner-component'><LoadSpinner /></div>;
  }

  // Função para lidar com o logout
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuth(null); // Atualiza o contexto
    navigate("/login");
  };

  return (
    <>
      <Head expanded={expanded}>
        <Navbar variant="dark" bg="dark" className="justify-content-between bar" sticky="top">
          <Image className="logo-cms" alt="logo" src={Logo} width="40" height="40" />
          <Navbar.Brand className="px-0">Sistema de Gerenciamento de Conteúdo</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="px-2">
              <p>{userName} - {userEmail}</p>
            </Navbar.Text>
          </Navbar.Collapse>
          <Form inline="true" className='form-bar'>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">Chatbot</Tooltip>}
            >
              <Button
                variant="outline-warning"
                className="mr-sm-2"
                size='sm'
                onClick={() => handleViewChange("chats")}
                // target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-comment-o" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">Mensagens do site</Tooltip>}
            >
              <Button
                variant="outline-primary"
                className="mr-sm-2"
                size='sm'
                onClick={() => handleViewChange("messages")}
                // target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-envelope" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Currículos</Tooltip>}>
              <Button
                variant="outline-success"
                className="mr-sm-2"
                size='sm'
                onClick={() => handleViewChange("curriculos")}
                // target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-id-card-o" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Logout</Tooltip>}>
              <Button
                variant="outline-danger"
                className="mr-sm-2"
                size='sm'
                onClick={handleLogout}
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-sign-out" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
          </Form>
        </Navbar>
      </Head>
      <Bar>
        <SideNavF
          expanded={expanded}
          onToggle={(expanded) => {
            setState(expanded)
          }}
          onSelect={(selected) => {
            const to = "#/" + selected
            if (view !== to) {
              setView(selected)
            }
          }}
        >
          <SideNav.Toggle />
          <SideNav.Nav selected={view} defaultSelected="dashboard">

            <NavItem eventKey="dashboard">
              <NavIcon>
                <i className="fa fa-line-chart" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Dashboard</NavText>
            </NavItem>

            <NavItem eventKey="home">
              <NavIcon>
                <i className="fa fa-fw fa-home" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Página Inicial</NavText>
            </NavItem>

            <NavItem eventKey="internet">
              <NavIcon>
                <i className="fa fa-fw  fa-wifi" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Página Internet</NavText>
            </NavItem>

            <NavItem eventKey="phone">
              <NavIcon>
                <i className="fa fa-fw fa-phone-square" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Página Telefone</NavText>
            </NavItem>

            <NavItem eventKey="tv">
              <NavIcon>
                <i className="fa fa-fw fa-television" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Página TV</NavText>
            </NavItem>

            <NavItem eventKey="rural">
              <NavIcon>
                <i className="fa fa-fw fa-signal" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Página Internet Rural</NavText>
            </NavItem>

            <NavItem eventKey="videos">
              <NavIcon>
                <i className="fa fa-fw fa-video-camera" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Vídeos Explicativos</NavText>
            </NavItem>

            <NavItem eventKey="combos">
              <NavIcon>
                <i className="fa fa-fw fa-clone" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Combos</NavText>
            </NavItem>

            <NavItem eventKey="cidades">
              <NavIcon>
                <i className="fa fa-fw fa-shopping-bag" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Lojas</NavText>
            </NavItem>

            <NavItem eventKey="products">
              <NavIcon>
                <i className="fa fa-fw fa-cart-arrow-down" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Produtos</NavText>
            </NavItem>

            {/* <Separator /> */}

            {/* <NavItem eventKey="message">
              <NavIcon>
                <i className="fa fa-fw fa-envelope" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Mensagens do Site</NavText>
            </NavItem> */}

            {/* <NavItem eventKey="curriculos">
              <NavIcon>
                <i className="fa fa-fw fa-cogs" style={{ fontSize: "1.5em" }} />
              </NavIcon>
              <NavText>Currículos</NavText>
            </NavItem> */}
          </SideNav.Nav>
        </SideNavF>
        <Main expanded={expanded}>
          {view === "dashboard" && <Dashboard />}
          {view === "home" && <Home />}
          {view === "internet" && <Internet />}
          {view === "phone" && <Phone />}
          {view === "card" && <CardSale />}
          {view === "offer" && <CardOffer />}
          {view === "tv" && <Tv />}
          {view === "rural" && <RuralInternet />}
          {view === "messages" && <MessagesTable />}
          {view === "chats" && <ChatbotUsersTable />}
          {view === "combos" && <Combos />}
          {view === "about" && <About />}
          {view === "videos" && <Videos />}
          {view === "cidades" && <Cidades />}
          {view === "curriculos" && <ListaCurriculos />}
          {view === "curriculos/:id" && <CurriculoView />}
          {/* {view === "curriculos/:id" && <GenerateCurriculumPDF />} */}
          {view === "products" && <Products />}
        </Main>
        <Navbar variant="light" bg="light" className="justify-content-between border-top">
          <Navbar.Brand className="px-1"></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="footer">
            <img src={LogoGiga} alt='logo' />
            <Navbar.Text> © Gigabyte Telecom {new Date().getFullYear()}</Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </Bar>
    </>
  );
}

export default Menu;
