import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';

// Importa e registra todos os componentes do Chart.js automaticamente
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(...registerables);

const ChatbotUsersTimelineChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chatbotusers`);
      const users = response.data;

      const interacoesPorData = {};
      users.forEach(user => {
        const dataFormatada = new Date(user.createdAt).toISOString().split('T')[0]; // YYYY-MM-DD
        interacoesPorData[dataFormatada] = (interacoesPorData[dataFormatada] || 0) + 1;
      });

      setChartData({
        labels: Object.keys(interacoesPorData),
        datasets: [
          {
            label: 'Interações ao longo do tempo',
            data: Object.values(interacoesPorData),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.4,
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
          },
        ],
      });
    } catch (error) {
      console.error('Erro ao buscar dados para o gráfico:', error);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
    },
    scales: {
      x: {
        type: 'time',  // Certifique-se de que agora funciona com o registro global
        time: {
          unit: 'day',
          tooltipFormat: 'yyyy-MM-dd',
          displayFormats: {
            day: 'MMM dd',
          },
        },
        // title: {
        //   display: true,
        //   text: 'Data das interações',
        // },
      },
      // y: {
      //   beginAtZero: true,
      //   title: {
      //     display: true,
      //     text: 'Número de Interações',
      //   },
      // },
    },
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <h3>Interações ao Longo do Tempo</h3>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ChatbotUsersTimelineChart;
