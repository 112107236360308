import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Configuração global do axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Interceptor de resposta para capturar erros 401 (sessão expirada)
api.interceptors.response.use(
  (response) => response, // Caso a resposta seja bem-sucedida, apenas retorna
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error('Sua sessão expirou. Faça login novamente.');

      // Remove o token inválido do localStorage
      localStorage.removeItem('token');

      // Redireciona para a página de login
      window.location.href = '/login';
    }

    return Promise.reject(error); // Retorna o erro para tratamento posterior
  }
);

export default api;
