import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Spinner } from 'react-bootstrap';
import generateCurriculumPDF from './GenerateCurriculumPDF'; // Importe a função para gerar o PDF
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './CurriculoView.css';

const ListaCurriculos = () => {
  const [curriculos, setCurriculos] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [generatingPdf, setGeneratingPdf] = useState(null); // Estado para controle do PDF (currículo específico)
  const navigate = useNavigate();

  // Função para buscar todos os currículos
  const fetchCurriculos = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtém o token do localStorage

      if (!token) {
        throw new Error('Token não encontrado. Por favor, faça login novamente.');
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos`, {
        headers: {
          Authorization: `Bearer ${token}`, // Envia o token no cabeçalho
        },
      });
      setCurriculos(response.data);
    } catch (error) {
      console.error('Erro ao buscar os currículos:', error);
      if (error.response && error.response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        setTimeout(() => {
          navigate('/login'); // Redireciona para a página de login após 2 segundos
        }, 3000);
      } else {
        toast.error('Erro ao buscar os currículos. Tente novamente.');
      }
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  // Função para excluir um currículo
  const handleDelete = async (id) => {
    if (window.confirm('Você tem certeza que deseja excluir este currículo?')) {
      try {
        const token = localStorage.getItem('token'); // Obtém o token do localStorage

        if (!token) {
          throw new Error('Token não encontrado. Faça login novamente.');
        }

        await axios.delete(`${process.env.REACT_APP_API_URL}/curriculos/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Inclui o token de autenticação
          },
        });

        toast.success('Currículo excluído com sucesso!');

        // Remove o currículo deletado do estado para atualizar a lista sem precisar recarregar a página
        setCurriculos(curriculos.filter((curriculo) => curriculo._id !== id));
      } catch (error) {
        console.error('Erro ao excluir o currículo:', error);
        toast.error('Erro ao excluir o currículo. Tente novamente.');
      }
    }
  };


  // Função para gerar o PDF
  const handleGeneratePdf = async (curriculo) => {
    setGeneratingPdf(curriculo._id); // Define o currículo em processo de geração do PDF
    try {
      await generateCurriculumPDF(curriculo._id); // Chama a função de geração de PDF
      // toast.success('PDF gerado com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
      toast.error('Erro ao gerar o PDF. Tente novamente.');
    } finally {
      setGeneratingPdf(null); // Finaliza o controle do PDF
    }
  };

  useEffect(() => {
    fetchCurriculos();
  }, []);

  return (
    <div className="container mt-4">
      <div className="carousel-h3">
        <h3><i className="fa fa-th-list" aria-hidden="true"></i> Lista de Currículos</h3>
      </div>
      <div className="list-curriculo">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Carregando currículos...</p>
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {curriculos.map((curriculo) => {
                const storedUser = JSON.parse(localStorage.getItem('user')) || {};
                const userName = curriculo.userId?.name || storedUser.name || 'Nome não informado';
                const userEmail = curriculo.userId?.email || storedUser.email || 'Email não informado';

                return (
                  <tr key={curriculo._id}>
                    <td>{userName}</td>
                    <td>{userEmail}</td>
                    <td>{curriculo.telefone || 'Telefone não informado'}</td>
                    <td className="acoes-table">
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => handleGeneratePdf(curriculo)}
                        disabled={generatingPdf === curriculo._id}
                      >
                        {generatingPdf === curriculo._id ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Gerando...
                          </>
                        ) : (
                          <>
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </>
                        )}
                      </Button>{' '}
                      <Button variant="outline-danger" size='sm' onClick={() => handleDelete(curriculo._id)}>
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>

          </Table>
        )}
      </div>
    </div>
  );
};

export default ListaCurriculos;
