import React, { useState, useContext } from "react";
import axios from "axios";
import { Form, FormControl, FormGroup, Card } from 'react-bootstrap';
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logoGiga.png";
import CMS from "../../assets/images/cms-icon-9.png";
import Separator from "../../assets/images/separator.png";
import Bots from "../../assets/images/build-Photoroom.png";
import { toast, ToastContainer } from 'react-toastify';
import jwtDecode from "jwt-decode"; // Importe o jwt-decode

import "./LoginForm.css";

const LoginForm = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  // Quando você definir o auth no login:
  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
  //       email,
  //       password,
  //     });

  //     const token = response.data.accessToken;
  //     const decoded = jwtDecode(token); // Decodifica o token
  //     setAuth({ token, userId: decoded.userId, userName: decoded.name }); // Armazena as informações no contexto
  //     console.log(decoded.userName);
  //     navigate("/menu");
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       setErrorMsg("Credenciais inválidas, por favor tente novamente.");
  //     } else {
  //       setErrorMsg("Credenciais inválidas, por favor tente novamente.");
  //     }
  //     console.error(error.response.data.msg);
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password: password,
      });
  
      const { accessToken, user } = response.data;
  
      if (!accessToken || !user) {
        throw new Error("Resposta inválida do servidor");
      }
  
      // Armazene o token e o usuário no localStorage
      localStorage.setItem('token', accessToken);
      localStorage.setItem('user', JSON.stringify(user));
  
      // Atualize o contexto de autenticação (opcional)
      setAuth({ token: accessToken, user });
  
      toast.success('Login bem-sucedido!', { position: 'top-center' });
  
      // Redirecione após o login
      setTimeout(() => {
        navigate('/menu');
      }, 3000);
    } catch (error) {
      console.error('Erro no login:', error);
      toast.error('Erro ao tentar fazer login. Credenciais inválidas', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="login-bg">
      <ToastContainer />
      <div className="container">
        <div className="content-login mx-auto text-center" style={{ width: "80%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                {/* Primeira coluna */}
                <div className="col-sm-12 col-md-6 gap-5">
                  <div className="login">
                    <div className="cms-logo">
                      <img src={CMS} alt="" />
                      <p>Content Management System</p>
                    </div>
                    <div className="separator">
                      <img src={Separator} alt="separator" />
                    </div>

                    {/* Exibe a mensagem de erro se houver */}
                    {errorMsg && <p className="text-danger">{errorMsg}</p>}

                    <Form>
                      <FormGroup className="py-2">
                        <FormControl
                          type="email"
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="py-2">
                        <FormControl
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                      <div className="botao">
                        <div className="btn-hover color-7" onClick={handleLogin}>
                          Conectar
                        </div>
                      </div>
                    </Form>
                  </div>
                  <br />
                  <div className="separator">
                    <img src={Separator} alt="separator" />
                  </div>
                  <div className="logo-giga">
                    <img src={Logo} alt="" />
                  </div>
                </div>

                {/* Segunda coluna */}
                <div className="col-sm-12 col-md-6">
                  <div className="bots">

                    <img src={Bots} alt="" />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );


};

export default LoginForm;