import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import './CurriculumStats.css';

const CurriculumStatsAreas = () => {
  const [areasStats, setAreasStats] = useState([]); // Estado correto para áreas de interesse

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token'); // Obtendo o token do localStorage

        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/curriculos/stats/areas`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAreasStats(response.data);
      } catch (error) {
        console.error("Erro ao buscar estatísticas das áreas de interesse:", error);
      }
    };

    fetchStats();
  }, []);


  // Dados para gráfico de pizza (áreas de interesse)
  const areasData = {
    labels: areasStats.map((stat) => stat._id),
    datasets: [
      {
        data: areasStats.map((stat) => stat.count),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,  // Permite controle total sobre tamanho
    plugins: {
      legend: {
        display: true,
        position: 'bottom',  // Posiciona a legenda abaixo do gráfico
        maxHeight: 50,       // Define um limite máximo para a altura da legenda
        labels: {
          boxWidth: 10, // Reduz o tamanho do retângulo da legenda 
          padding: 10, // Adiciona espaçamento entre os itens da legenda         
          font: {
            size: 12, // Ajusta o tamanho da fonte
          },
          usePointStyle: true,  // Usa estilos de pontos menores
          padding: 10,          // Espaçamento interno entre os itens da legenda
        },
      },
      tooltip: {
        enabled: true, // Exibe tooltips ao passar o mouse
      },
    },
  };

  return (
    <div className="chart-stat-container">
      <h3 className="chart-stat-title">Currículos por Áreas de Interesse</h3>
      <div className="chart-content-stats">
        <Pie data={areasData} options={options} />
      </div>
    </div>
  );
};

export default CurriculumStatsAreas;
